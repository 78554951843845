<template src="./match.html" />

<script>
import { computed } from "vue"
import { useStore } from "@/store/store.js"
import mixins from "@/mixins/mixins.js"

export default {
  mixins: [mixins],
  data() {
    return {
      windowWidth: 0,
      attendanceType: null,
      allowPresential: true,
      buildRequest: null,
      matches: [],
      currentPage: 1,
      limitsPerPage: 10,
      totalPages: 1,
      btnClick: false,
      valor: [],
      zoom: 17,
      center: [-49.24135571700164, -25.45750014559853],
      rotation: 0,
      geolocPosition: undefined,
      googlecenter: { lat: 45.508, lng: -73.587 },
      location: {
        lat: -23.5489,
        lng: -46.6388,
      },
      googlemarkers: [],
      nomeCliente: "",
      search: "",
      geolocation: false,
      matchNumber: 0,
      hideButton: true,
      maxPages: 5,
      resultsPaginated: [],
      selectedClinic: null,
      isDasa: false,
      attendanceDistance: 100,
      doctorRealNameList: [],
    };
  },
  setup() {
    const store = useStore()

    return {
      isDesktop: computed(() => store.state.isDesktop),
      weekdays: [
        {
          label: "Seg",
          code: "MONDAY",
        },
        {
          label: "Ter",
          code: "TUESDAY",
        },
        {
          label: "Qua",
          code: "WEDNESDAY",
        },
        {
          label: "Qui",
          code: "THURSDAY",
        },
        {
          label: "Sex",
          code: "FRIDAY",
        },
        {
          label: "Sáb",
          code: "SATURDAY",
        },
        {
          label: "Dom",
          code: "SUNDAY",
        },
      ],
    }
  },
  async mounted() {
    this.init()

    this.buildRequest = this.$session.get("buildRequest")
    this.isDasa = this.$session.get("isDasa")
    this.matches = this.$session.get("matches")
  },
  computed: {
    computedMatches() {
      const initialSlice = this.limitsPerPage * (this.currentPage - 1)
      const finalSlice = this.limitsPerPage * this.currentPage

      const matches = this.matches
        .map((match) => ({
          ...match,
          attendanceType: match.isDasa ? 1 : match.attendanceType,
        }))
        .filter((match) => match.attendanceType === 1)
        .filter((match) => {
          return parseInt(this.attendanceDistance) === 0 ||
            (match.distanceFromSolicitation / 1000).toFixed(2) <= parseInt(this.attendanceDistance);
        })
        .filter((match) => {
          const clinicName = match
            .clinicName
            .toLowerCase();

          const doctorName = match
            .doctor
            .name
            .toLowerCase();

          return this.search === "" ||
            clinicName.includes(this.search.toLowerCase()) ||
            doctorName.includes(this.search.toLowerCase());
        });

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.totalPages = Math.ceil(matches.length / this.limitsPerPage)

      return matches.slice(initialSlice, finalSlice)
        .map((match) => ({
          ...match,
          doctorRealName: this.getRealDoctorName(match.doctor.id),
        }))
    },
    isPaginated() {
      return this.totalPages > 1
    },
    isAttendanceRemote() {
      return this.attendanceType === "attendanceTypeVideo"
    },
  },
  methods: {
    init() {
      this.geolocation = false
      this.nomeCliente = this.$session.get("name")

      if (this.$session.get("location")) {
        this.geolocation = true
        this.location = {
          lat: parseFloat(this.$session.get("location").latitude),
          lng: parseFloat(this.$session.get("location").longitude),
        }
      }

      window.scrollTo({ top: 0, behavior: "smooth" })
    },
    googleCoords(tr) {
      return {
        lat: parseFloat(tr.addressGps.latitude),
        lng: parseFloat(tr.addressGps.longitude),
      }
    },
    googleMarker(id) {
      return [{ position: this.googleCoords(id) }]
    },
    async setSchedule(clinic) {
      if (this.btnClick) {
        return
      }

      this.btnClick = true
      await this.$vs.loading()

      if (clinic.isDasa) {
        this.selectedClinic = clinic
        this.btnClick = false
        await this.$vs.loading.close()
        await this.$bvModal.show("modal-match-dasa")
      } else {
        this.axios.post(
          `${this.$store.state.filooServer}schedules/post-indicated-doctor/${clinic.doctor.id}/${clinic.day.webClinicId}`,
          {
            ...this.buildRequest,
            attendanceType: clinic.attendanceType,
            webClinicAttendanceId: clinic.day.id,
          },
          { headers: { Authorization: this.$session.get("bearer") } }
        )
          .then(() => {
            this.$vs.loading.close()
            this.$bvModal.show("modal-consultation-successfully-requested")
          })
      }
    },
    goToPendencyList() {
      this.$bvModal.hide("modal-consultation-successfully-requested")
      this.$router.push({ name: "pendency", params: {} })
    },
    showPrice (event) {
      let target = event.target

      while (!target.attributes.id) {
        target = target.parentElement
      }

      if (!target.classList.contains("div-prices--reveal")) {
        target.classList.add("div-prices--reveal")
      }
    },
    hidePrice (event) {
      const target = event.target

      if (target.classList.contains("div-prices--reveal")) {
        target.classList.remove("div-prices--reveal")
      }
    },
    goToWallet() {
      this.$router.push({ name: "wallet", params: {} })
      this.$bvModal.hide("modal-match-balance-lack")
    },
    async getRealDoctorName(id) {

      const doctor = this.doctorRealNameList.find((row)=> row.id === id);
      if(doctor){
        return doctor.name;
      }

      return await this.axios.get(
        `${this.$store.state.filooServer}patient/doctor-name/${id}`,
        { headers: { Authorization: this.$session.get("bearer") } }
      )
        .then(({ data }) => {
          this.doctorRealNameList.push({ id, name: data });
          return data;
        })
        .catch((error) => console.log({ location: "getRealDoctorName", error }))
    },
  },
  watch: {
    attendanceType() {
      this.currentPage = 1
    },
    currentPage() {
      window.scrollTo({ top: 0, behavior: "smooth" })
    },
    showAvailable(doc, day) {
      return !doc.day.days || doc.day.days.length == 0 || doc.day.days.includes(day);
    },
  },
};
</script>

<style lang="scss" src="./match.scss" scoped />
