import { render, staticRenderFns } from "./match.html?vue&type=template&id=7938f5e6&scoped=true&external"
import script from "./match.vue?vue&type=script&lang=js"
export * from "./match.vue?vue&type=script&lang=js"
import style0 from "./match.scss?vue&type=style&index=0&id=7938f5e6&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7938f5e6",
  null
  
)

export default component.exports